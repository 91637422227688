@value v_cornerRadiusSm, v_cornerRadiusMd from '~parallaxSettings';

.pageSectionWrapper {
  position: relative;

  --corner-radius: v_cornerRadiusSm;

  @media (--md) {
    --corner-radius: v_cornerRadiusMd;
  }

  /* the start position of the parallaxing element (negative y value for below sections, positive y value for above sections) */
  &.default {
    --parallax-start-y: calc(var(--parallax-overlap-y, 0) * 2);
    @media (--md) {
      --parallax-overlap-y: 9.6rem;
    }

    @media (--lg) {
      --parallax-overlap-y: 12.4rem;
    }
  }

  &.light {
    @media (--md) {
      --parallax-overlap-y: 9.6rem;
    }

    @media (--lg) {
      --parallax-overlap-y: 12.4rem;
    }
  }

  &.heavy {
    @media (--md) {
      --parallax-start-y: calc(0.5 * 100vh);
    }
  }
}

.shadowWrapper {
  position: absolute;
  z-index: var(--z-index-neg);
  width: 100%;
}

.shadow {
  pointer-events: none;
  transform: translateY(calc(-1 * var(--corner-radius) / 4));
}

.pageSection {
  position: relative;
  box-sizing: content-box;

  /* Sections have a black bg by default because of the rounded corners.
  /* The section background can be overidden by the Brand Studio Tools debug extension. */

  /* See: web/src/components/atoms/Debug/Debug.tsx */
  background: var(--section-debug-background, var(--section-background, black));
  will-change: transform;

  /* section layer for handling section opacity / instead of fading out section
  we're fading in a opaque layer, to prevent section peeking through while section opacity value is low */
  &::before {
    position: absolute;
    z-index: 5;
    background-color: black;
    content: '';
    inset: 0;
    opacity: var(--section-layer-opacity, 0);
    pointer-events: none;
  }

  /* If the section has a FAB, we need an extra 46px of space at the bottom
    We also need to add extra space (depending on the BP) above/bellow the FAB */

  /* The section doesn't need extra space for the FAB if it's last in the section */

  --fab-margin-top: var(--spacer-40);
  @media (--md) {
    --fab-margin-top: var(--spacer-48);
  }

  &.hasFab {
    &::after {
      display: block;
      width: 100%;
      content: '';
    }

    &:not(.hasFabLast) {
      &::after {
        height: var(--fab-height);
        padding-top: var(--fab-margin-top);
      }
    }
  }
}

.hasRoundedTop {
  mask-image: var(--top-mask-sm);

  @media (--md) {
    mask-image: var(--top-mask-md);
  }
}

.hasRoundedBottom {
  mask-image: var(--bottom-mask-sm);

  @media (--md) {
    mask-image: var(--bottom-mask-md);
  }
}

.hasRoundedBottom.hasRoundedTop {
  mask-image: var(--top-bottom-mask-sm);

  @media (--md) {
    mask-image: var(--top-bottom-mask-md);
  }
}

.hasOverlapTop {
  margin-top: calc(-1 * var(--corner-radius));
}

.hasOverlapBottom {
  margin-bottom: calc(-1 * var(--corner-radius));

  /* No extra space for sections that are covered, except for mobile where there's no parallax */
  .hasFab {
    &::after {
      margin-bottom: calc(var(--spacer-84) + var(--corner-radius));

      @media (--md) {
        margin-bottom: 0;
      }

      @media (--lg) {
        margin-bottom: 0;
      }
    }
  }

  /* Compensates for the extra padding bottom to allow for the overlap */
  .dummyBottom {
    bottom: var(--default-padding-bottom);
  }
}

.isRevealedSection {
  transform: translateY(calc(-1 * var(--parallax-start-y)));
}

.isCoveringSection {
  transform: translateY(var(--parallax-start-y));
}

.isNextSectionSameLevel {
  padding-bottom: calc(var(--spacer-84) / 2);

  @media (--md) {
    padding-bottom: calc(var(--spacer-96) / 2);
  }

  @media (--lg) {
    padding-bottom: calc(var(--spacer-124) / 2);
  }
}

.isSameLevelSection {
  padding-top: calc(var(--spacer-84) / 2);

  @media (--md) {
    padding-top: calc(var(--spacer-96) / 2);
  }

  @media (--lg) {
    padding-top: calc(var(--spacer-124) / 2);
  }
}

.dummyTop {
  position: absolute;
  top: var(--corner-radius);
  width: 100vw;
  height: 10px;
  pointer-events: none;
}

.above {
  /* above sections will start at a lower y value and move to 0, creating transparent space at the top before it fully covers the previous section, so we want to ensure that if there are elements under this transparent space, its pointer events will not be covered */
  pointer-events: none;

  .pageSection {
    pointer-events: all;
  }

  .dummyTop {
    top: 0;
  }
}

.dummyBottom {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 10px;
  pointer-events: none;
}

.overflowHidden {
  overflow: hidden;
}
