.richTextDocument {
  position: relative;
  padding-top: var(--spacer-124);
  padding-bottom: var(--spacer-124);
}

.container {
  composes: grid from '~g';
  position: relative;
}

.content {
  grid-column: start / end;
}
