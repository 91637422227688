.wrapper {
  position: absolute;
  z-index: 999999;
  top: 16rem;
  right: -44rem;
  padding: 3.2rem;
  opacity: 0.25;
  transition: all 0.1s ease-out;

  &:hover {
    right: 0;
    opacity: 1;
  }
}

.container {
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 8px;
  backdrop-filter: blur(16px);
  background-color: rgb(0 0 0 / 65%);
  box-shadow: 0 0 32px black;
  font-family: monospace;
  font-size: 12px;
  user-select: none;

  .title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    background: repeating-linear-gradient(-55deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%) 10px, rgb(255 255 255 / 10%) 10px, rgb(255 255 255 / 10%) 11px);
    background-color: rgb(0 0 0 / 20%);
    cursor: move;
  }

  .titleLabel {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .titleButtonGroup {
    button {
      padding: 0.75rem 1rem;
      border: 1px solid rgb(255 255 255 / 20%);
      border-radius: 4px;
      margin-left: 1rem;
      background-color: black;

      &:hover {
        border-color: rgb(200 220 255 / 50%);
      }

      &:active {
        background-color: rgb(200 220 255 / 50%);
      }
    }
  }

  .properties {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    label {
      width: 140px;
      color: #c0c0c0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .value {
      color: white;
      text-align: right;
    }
  }
}

.group {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 1rem;
}

.groupTitle {
  padding: 1rem 1.6rem;
  border-top: 1px solid rgb(255 255 255 / 10%);
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  margin-top: -1.6rem;
  margin-right: -1.6rem;
  margin-left: -1.6rem;
  background-color: rgb(0 0 0 / 25%);
  color: rgb(180 200 240);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.numericSlider {
  display: flex;
  align-items: center;
  gap: 2rem;

  input {
    width: 200px;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 2rem;
}
