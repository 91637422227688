.quoteInteractiveMd {
  position: relative;

  --lockup-column-span: 8;
}

.container {
  composes: grid from '~g';
  margin-bottom: var(--spacer-32);

  @media (--md) {
    margin-bottom: var(--spacer-64);
  }
}
