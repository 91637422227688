.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  left: 0;

  /* ideally the width & height properties should be set below so that we are
  requesting the correct sized image from the start, however due to a bug in
  the current implementation we get an incrrectly cropped image when using the
  CMS cropping tool.

  Ticket: https://frame-io.atlassian.net/browse/FMWR-3434
  */
  width: 100%;
  height: 100%;
}

.anchorTop {
  top: 0;
  bottom: auto;
}

.anchorBottom {
  top: auto;
  bottom: 0;
}

.blendNormal {
  mix-blend-mode: normal;
}

.blendMultiply {
  mix-blend-mode: multiply;
}

.blendScreen {
  mix-blend-mode: screen;
}

.blendOverla {
  mix-blend-mode: overlay;
}

.blendDarken {
  mix-blend-mode: darken;
}

.blendLighten {
  mix-blend-mode: lighten;
}

.blendColorDodge {
  mix-blend-mode: color-dodge;
}

.blendColorBurn {
  mix-blend-mode: color-burn;
}

.blendHardLight {
  mix-blend-mode: hard-light;
}

.blendSoftLight {
  mix-blend-mode: soft-light;
}

.blendDifference {
  mix-blend-mode: difference;
}

.blendExclusion {
  mix-blend-mode: exclusion;
}

.blendHue {
  mix-blend-mode: hue;
}

.blendSaturation {
  mix-blend-mode: saturation;
}

.blendColor {
  mix-blend-mode: color;
}

.blendLuminosity {
  mix-blend-mode: luminosity;
}

.blendPlusDarker {
  mix-blend-mode: plus-darker;
}

.blendPlusLighter {
  mix-blend-mode: plus-lighter;
}
