.standaloneText {
  position: relative;
}

.container {
  composes: grid from '~g';
  position: relative;
  grid-column: start / end;
}

.columnsWrapper {
  composes: subgrid from '~g';
  row-gap: var(--spacer-8);

  --subgrid-start: start;
  --subgrid-width: var(--grid-number-of-columns);

  @media (--md) {
    row-gap: 0;
  }

  .contentCol1 {
    grid-column: start / end;

    @media (--md) {
      grid-column: col 2 / span 3;

      --subgrid-start: col 2;
      --subgrid-width: 3;
    }

    @media (--lg) {
      grid-column: col 3 / span 3;

      --subgrid-start: col 3;
      --subgrid-width: 3;
    }

    &.sizeWide {
      @media (--lg) {
        grid-column: col 2 / span 3;

        --subgrid-start: col 2;
        --subgrid-width: 3;
      }
    }
  }

  .contentCol2 {
    grid-column: start / end;

    @media (--md) {
      grid-column: col 7 / span 5;

      --subgrid-start: col 7;
      --subgrid-width: 5;
    }

    @media (--lg) {
      grid-column: col 7 / span 4;

      --subgrid-start: col 7;
      --subgrid-width: 4;
    }

    &.sizeWide {
      @media (--lg) {
        grid-column: col 6 / span 6;

        --subgrid-start: col 6;
        --subgrid-width: 6;
      }
    }
  }
}

.icon {
  margin-bottom: var(--spacer-20);

  @media (--md) {
    margin-bottom: var(--spacer-24);
  }
}

.titles {
  &:has(.colorHighlight) {
    color: var(--color-alpha-light-50);
  }

  .colorHighlight {
    color: var(--color-grey-100);
  }
}
