.touts {
  grid-column: start / end;
}

.container {
  --tout-width: 4;
  --shadow-scale: 0.5;
  --shadow-radius: var(--border-radius-20);
  composes: subgrid from '~g';
  position: relative;
  row-gap: var(--spacer-32);

  @media (--md) {
    --subgrid-start: start;
    --subgrid-width: var(--grid-number-of-columns);
  }

  &.toutsOnTiles {
    row-gap: var(--spacer-24);
  }

  &.isMobileSlider {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    grid-column-gap: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;

    @media (--md) {
      display: grid;

      /* put back default gap */
      grid-column-gap: var(--grid-gutter-size, 20px);
    }
  }

  &.mobileSmallLayout {
    --tout-width: 3;
  }

  &.isColumnLayout {
    --subgrid-start: var(--column-start);
    --subgrid-width: var(--column-width, var(--tout-width));

    .tout {
      position: relative;
    }
  }

  &.with3Touts {
    @media (--md) {
      --tout-width: 4;
    }
  }

  &.with4Touts {
    @media (--md) {
      --tout-width: 6;
    }
    @media (--lg) {
      --tout-width: 3;
    }
  }
}

.paginationDots {
  display: block;
  margin-top: var(--spacer-24);

  @media (--md) {
    display: none;
  }
}

.tout {
  position: relative;
  grid-column: auto / span var(--tout-width);
}

.toutInnerWrapper {
  position: relative;
  width: 100%;
  flex-basis: var(--grid-computed-inner-width);
  flex-shrink: 0;
  grid-column: auto / span var(--tout-width);

  @media (--md) {
    flex-basis: unset;
  }
}

.iconWrapper {
  position: relative;
  display: flex;
  width: auto;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-bottom: var(--spacer-8);

  &.isLogo {
    align-items: center;
    margin-bottom: var(--spacer-16);
  }

  &:not(.isLogo) {
    .icon {
      * {
        fill: var(--color-accent);
      }
    }
  }
}

.hidden {
  display: none;
}

.backgroundGlow {
  position: absolute;
  z-index: var(--z-index-neg);
  inset: 0;
  transform: translate3d(0, 25%, 0);

  @media (--md) {
    transform: translate3d(0, 40%, 0);
  }
}
