.Debug_debug__V4_ZI {
  position: fixed;
  z-index: var(--z-index-neg);
  width: 100vw;
  inset: 0;
  pointer-events: none;
  visibility: hidden;
}
  @media (min-width: 768px) {.Debug_debug__V4_ZI {
    width: 100%
}
  }

.Debug_visible__Tb39B {
  z-index: var(--z-index-max);
  visibility: visible;
}

.Debug_column__bLc20 {
  background-color: var(--color-cobalt);
  opacity: 0.3;
}

.Debug_fpsCounter__otGwc {
  position: fixed;
  z-index: var(--z-index-max);
  top: 10px;
  left: 10px;
  color: red;
  opacity: 0.5;
}

/* Default spacing around section rounded caps */

.QuoteInteractiveMd_quoteInteractiveMd__2EVbB {
  position: relative;

  --lockup-column-span: 8;
}

.QuoteInteractiveMd_container__Dghuc {
  margin-bottom: var(--spacer-32);
}

@media (min-width: 768px) {

.QuoteInteractiveMd_container__Dghuc {
    margin-bottom: var(--spacer-64)
}
  }

.QuoteInteractiveSm_quoteInteractiveSm__oqrXK {
  position: relative;
}

.QuoteInteractiveSm_container__pyi_W {
  margin-bottom: var(--spacer-32);
}

.RichTextDocument_richTextDocument__doNzI {
  position: relative;
  padding-top: var(--spacer-124);
  padding-bottom: var(--spacer-124);
}

.RichTextDocument_container__8FA4t {
  position: relative;
}

.RichTextDocument_content__IzMk7 {
  grid-column: start / end;
}

.StandaloneText_standaloneText__L1OFJ {
  position: relative;
}

.StandaloneText_container__OjNQe {
  position: relative;
  grid-column: start / end;
}

.StandaloneText_columnsWrapper__q0gKo {
  row-gap: var(--spacer-8);

  --subgrid-start: start;
  --subgrid-width: var(--grid-number-of-columns);
}

@media (min-width: 768px) {

.StandaloneText_columnsWrapper__q0gKo {
    row-gap: 0
}
  }

.StandaloneText_columnsWrapper__q0gKo .StandaloneText_contentCol1__rzgUP {
    grid-column: start / end;
  }

@media (min-width: 768px) {

.StandaloneText_columnsWrapper__q0gKo .StandaloneText_contentCol1__rzgUP {
      grid-column: col 2 / span 3;

      --subgrid-start: col 2;
      --subgrid-width: 3
  }
    }

@media (min-width: 1240px) {

.StandaloneText_columnsWrapper__q0gKo .StandaloneText_contentCol1__rzgUP {
      grid-column: col 3 / span 3;

      --subgrid-start: col 3;
      --subgrid-width: 3
  }
    }

@media (min-width: 1240px) {

.StandaloneText_columnsWrapper__q0gKo .StandaloneText_contentCol1__rzgUP.StandaloneText_sizeWide__J999L {
        grid-column: col 2 / span 3;

        --subgrid-start: col 2;
        --subgrid-width: 3
    }
      }

.StandaloneText_columnsWrapper__q0gKo .StandaloneText_contentCol2__NnJ3n {
    grid-column: start / end;
  }

@media (min-width: 768px) {

.StandaloneText_columnsWrapper__q0gKo .StandaloneText_contentCol2__NnJ3n {
      grid-column: col 7 / span 5;

      --subgrid-start: col 7;
      --subgrid-width: 5
  }
    }

@media (min-width: 1240px) {

.StandaloneText_columnsWrapper__q0gKo .StandaloneText_contentCol2__NnJ3n {
      grid-column: col 7 / span 4;

      --subgrid-start: col 7;
      --subgrid-width: 4
  }
    }

@media (min-width: 1240px) {

.StandaloneText_columnsWrapper__q0gKo .StandaloneText_contentCol2__NnJ3n.StandaloneText_sizeWide__J999L {
        grid-column: col 6 / span 6;

        --subgrid-start: col 6;
        --subgrid-width: 6
    }
      }

.StandaloneText_icon__UCPyO {
  margin-bottom: var(--spacer-20);
}

@media (min-width: 768px) {

.StandaloneText_icon__UCPyO {
    margin-bottom: var(--spacer-24)
}
  }

.StandaloneText_titles__tMrxq:has(.StandaloneText_colorHighlight__9dJWW) {
    color: var(--color-alpha-light-50);
  }

.StandaloneText_titles__tMrxq .StandaloneText_colorHighlight__9dJWW {
    color: var(--color-grey-100);
  }

.PageSection_pageSectionWrapper__NvXar {
  position: relative;

  --corner-radius: 20px;
}

@media (min-width: 768px) {

.PageSection_pageSectionWrapper__NvXar {
    --corner-radius: 20px
}
  }

/* the start position of the parallaxing element (negative y value for below sections, positive y value for above sections) */

.PageSection_pageSectionWrapper__NvXar.PageSection_default__0Qk13 {
    --parallax-start-y: calc(var(--parallax-overlap-y, 0)*2);
  }

@media (min-width: 768px) {

.PageSection_pageSectionWrapper__NvXar.PageSection_default__0Qk13 {
      --parallax-overlap-y: 9.6rem
  }
    }

@media (min-width: 1240px) {

.PageSection_pageSectionWrapper__NvXar.PageSection_default__0Qk13 {
      --parallax-overlap-y: 12.4rem
  }
    }

@media (min-width: 768px) {

.PageSection_pageSectionWrapper__NvXar.PageSection_light__3S4_b {
      --parallax-overlap-y: 9.6rem
  }
    }

@media (min-width: 1240px) {

.PageSection_pageSectionWrapper__NvXar.PageSection_light__3S4_b {
      --parallax-overlap-y: 12.4rem
  }
    }

@media (min-width: 768px) {

.PageSection_pageSectionWrapper__NvXar.PageSection_heavy__NVKXI {
      --parallax-start-y: 50vh
  }
    }

.PageSection_shadowWrapper__5_d_J {
  position: absolute;
  z-index: var(--z-index-neg);
  width: 100%;
}

.PageSection_shadow__tRUSL {
  pointer-events: none;
  transform: translateY(calc(var(--corner-radius)*-1/4));
}

.PageSection_pageSection__8v9It {
  position: relative;
  box-sizing: content-box;

  /* Sections have a black bg by default because of the rounded corners.
  /* The section background can be overidden by the Brand Studio Tools debug extension. */

  /* See: web/src/components/atoms/Debug/Debug.tsx */
  background: var(--section-debug-background, var(--section-background, black));
  will-change: transform;
}

/* section layer for handling section opacity / instead of fading out section
  we're fading in a opaque layer, to prevent section peeking through while section opacity value is low */

.PageSection_pageSection__8v9It::before {
    position: absolute;
    z-index: 5;
    background-color: black;
    content: '';
    inset: 0;
    opacity: var(--section-layer-opacity, 0);
    pointer-events: none;
  }

.PageSection_pageSection__8v9It{

  --fab-margin-top: var(--spacer-40);
}

/* If the section has a FAB, we need an extra 46px of space at the bottom
    We also need to add extra space (depending on the BP) above/bellow the FAB */

/* The section doesn't need extra space for the FAB if it's last in the section */

@media (min-width: 768px) {

.PageSection_pageSection__8v9It {
    --fab-margin-top: var(--spacer-48)
}
  }

.PageSection_pageSection__8v9It.PageSection_hasFab__8mXiL::after {
      display: block;
      width: 100%;
      content: '';
    }

.PageSection_pageSection__8v9It.PageSection_hasFab__8mXiL:not(.PageSection_hasFabLast__I0GwH)::after {
        height: var(--fab-height);
        padding-top: var(--fab-margin-top);
      }

.PageSection_hasRoundedTop__rLxce {
  -webkit-mask-image: var(--top-mask-sm);
          mask-image: var(--top-mask-sm);
}

@media (min-width: 768px) {

.PageSection_hasRoundedTop__rLxce {
    -webkit-mask-image: var(--top-mask-md);
            mask-image: var(--top-mask-md)
}
  }

.PageSection_hasRoundedBottom__eMhqV {
  -webkit-mask-image: var(--bottom-mask-sm);
          mask-image: var(--bottom-mask-sm);
}

@media (min-width: 768px) {

.PageSection_hasRoundedBottom__eMhqV {
    -webkit-mask-image: var(--bottom-mask-md);
            mask-image: var(--bottom-mask-md)
}
  }

.PageSection_hasRoundedBottom__eMhqV.PageSection_hasRoundedTop__rLxce {
  -webkit-mask-image: var(--top-bottom-mask-sm);
          mask-image: var(--top-bottom-mask-sm);
}

@media (min-width: 768px) {

.PageSection_hasRoundedBottom__eMhqV.PageSection_hasRoundedTop__rLxce {
    -webkit-mask-image: var(--top-bottom-mask-md);
            mask-image: var(--top-bottom-mask-md)
}
  }

.PageSection_hasOverlapTop___j_pg {
  margin-top: calc(var(--corner-radius)*-1);
}

.PageSection_hasOverlapBottom__DlCD4 {
  margin-bottom: calc(var(--corner-radius)*-1);
}

/* No extra space for sections that are covered, except for mobile where there's no parallax */

.PageSection_hasOverlapBottom__DlCD4 .PageSection_hasFab__8mXiL::after {
      margin-bottom: calc(var(--spacer-84) + var(--corner-radius));
    }

@media (min-width: 768px) {

.PageSection_hasOverlapBottom__DlCD4 .PageSection_hasFab__8mXiL::after {
        margin-bottom: 0
    }
      }

@media (min-width: 1240px) {

.PageSection_hasOverlapBottom__DlCD4 .PageSection_hasFab__8mXiL::after {
        margin-bottom: 0
    }
      }

/* Compensates for the extra padding bottom to allow for the overlap */

.PageSection_hasOverlapBottom__DlCD4 .PageSection_dummyBottom__sm9bP {
    bottom: var(--default-padding-bottom);
  }

.PageSection_isRevealedSection__sEtWE {
  transform: translateY(calc(var(--parallax-start-y)*-1));
}

.PageSection_isCoveringSection__6U2BO {
  transform: translateY(var(--parallax-start-y));
}

.PageSection_isNextSectionSameLevel__ujiEc {
  padding-bottom: calc(var(--spacer-84)/2);
}

@media (min-width: 768px) {

.PageSection_isNextSectionSameLevel__ujiEc {
    padding-bottom: calc(var(--spacer-96)/2)
}
  }

@media (min-width: 1240px) {

.PageSection_isNextSectionSameLevel__ujiEc {
    padding-bottom: calc(var(--spacer-124)/2)
}
  }

.PageSection_isSameLevelSection__pBwzj {
  padding-top: calc(var(--spacer-84)/2);
}

@media (min-width: 768px) {

.PageSection_isSameLevelSection__pBwzj {
    padding-top: calc(var(--spacer-96)/2)
}
  }

@media (min-width: 1240px) {

.PageSection_isSameLevelSection__pBwzj {
    padding-top: calc(var(--spacer-124)/2)
}
  }

.PageSection_dummyTop__php_Q {
  position: absolute;
  top: var(--corner-radius);
  width: 100vw;
  height: 10px;
  pointer-events: none;
}

.PageSection_above__h92aO {
  /* above sections will start at a lower y value and move to 0, creating transparent space at the top before it fully covers the previous section, so we want to ensure that if there are elements under this transparent space, its pointer events will not be covered */
  pointer-events: none;
}

.PageSection_above__h92aO .PageSection_pageSection__8v9It {
    pointer-events: all;
  }

.PageSection_above__h92aO .PageSection_dummyTop__php_Q {
    top: 0;
  }

.PageSection_dummyBottom__sm9bP {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 10px;
  pointer-events: none;
}

.PageSection_overflowHidden__h80G_ {
  overflow: hidden;
}

.BackgroundImage_container__v0mKu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.BackgroundImage_image__RrPQH {
  position: absolute;
  left: 0;

  /* ideally the width & height properties should be set below so that we are
  requesting the correct sized image from the start, however due to a bug in
  the current implementation we get an incrrectly cropped image when using the
  CMS cropping tool.

  Ticket: https://frame-io.atlassian.net/browse/FMWR-3434
  */
  width: 100%;
  height: 100%;
}

.BackgroundImage_anchorTop__nQQ7d {
  top: 0;
  bottom: auto;
}

.BackgroundImage_anchorBottom__agoDv {
  top: auto;
  bottom: 0;
}

.BackgroundImage_blendNormal__juNRi {
  mix-blend-mode: normal;
}

.BackgroundImage_blendMultiply__Mwhz6 {
  mix-blend-mode: multiply;
}

.BackgroundImage_blendScreen__5OzXL {
  mix-blend-mode: screen;
}

.BackgroundImage_blendOverla__qyjmH {
  mix-blend-mode: overlay;
}

.BackgroundImage_blendDarken__0tNBV {
  mix-blend-mode: darken;
}

.BackgroundImage_blendLighten__P4w0v {
  mix-blend-mode: lighten;
}

.BackgroundImage_blendColorDodge__juiJ4 {
  mix-blend-mode: color-dodge;
}

.BackgroundImage_blendColorBurn__oIVot {
  mix-blend-mode: color-burn;
}

.BackgroundImage_blendHardLight__YkmYb {
  mix-blend-mode: hard-light;
}

.BackgroundImage_blendSoftLight__1LUCg {
  mix-blend-mode: soft-light;
}

.BackgroundImage_blendDifference__J1du5 {
  mix-blend-mode: difference;
}

.BackgroundImage_blendExclusion__YZfT1 {
  mix-blend-mode: exclusion;
}

.BackgroundImage_blendHue__mdion {
  mix-blend-mode: hue;
}

.BackgroundImage_blendSaturation__P6Rnj {
  mix-blend-mode: saturation;
}

.BackgroundImage_blendColor__RvGIL {
  mix-blend-mode: color;
}

.BackgroundImage_blendLuminosity__uuewJ {
  mix-blend-mode: luminosity;
}

.BackgroundImage_blendPlusDarker__nAzY7 {
  mix-blend-mode: plus-darker;
}

.BackgroundImage_blendPlusLighter__bBQml {
  mix-blend-mode: plus-lighter;
}

.DebugUI_wrapper__9g2mP {
  position: absolute;
  z-index: 999999;
  top: 16rem;
  right: -44rem;
  padding: 3.2rem;
  opacity: 0.25;
  transition: all 0.1s ease-out;
}

  .DebugUI_wrapper__9g2mP:hover {
    right: 0;
    opacity: 1;
  }

.DebugUI_container__er3yz {
  position: relative;
  overflow: hidden;
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 8px;
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
  background-color: rgb(0 0 0 / 65%);
  box-shadow: 0 0 32px black;
  font-family: monospace;
  font-size: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.DebugUI_container__er3yz .DebugUI_title__DsrvG {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    background: repeating-linear-gradient(-55deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 0%) 10px, rgb(255 255 255 / 10%) 10px, rgb(255 255 255 / 10%) 11px);
    background-color: rgb(0 0 0 / 20%);
    cursor: move;
  }

.DebugUI_container__er3yz .DebugUI_titleLabel__4tK2g {
    font-size: 1.6rem;
    font-weight: bold;
  }

.DebugUI_container__er3yz .DebugUI_titleButtonGroup__LQXvY button {
      padding: 0.75rem 1rem;
      border: 1px solid rgb(255 255 255 / 20%);
      border-radius: 4px;
      margin-left: 1rem;
      background-color: black;
    }

.DebugUI_container__er3yz .DebugUI_titleButtonGroup__LQXvY button:hover {
        border-color: rgb(200 220 255 / 50%);
      }

.DebugUI_container__er3yz .DebugUI_titleButtonGroup__LQXvY button:active {
        background-color: rgb(200 220 255 / 50%);
      }

.DebugUI_container__er3yz .DebugUI_properties__0DLIg {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

.DebugUI_container__er3yz .DebugUI_properties__0DLIg label {
      width: 140px;
      color: #c0c0c0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

.DebugUI_container__er3yz .DebugUI_properties__0DLIg .DebugUI_value__1gjNI {
      color: white;
      text-align: right;
    }

.DebugUI_group__wCbpl {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  gap: 1rem;
}

.DebugUI_groupTitle__yzxo5 {
  padding: 1rem 1.6rem;
  border-top: 1px solid rgb(255 255 255 / 10%);
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  margin-top: -1.6rem;
  margin-right: -1.6rem;
  margin-left: -1.6rem;
  background-color: rgb(0 0 0 / 25%);
  color: rgb(180 200 240);
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.DebugUI_numericSlider__T4ZJC {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.DebugUI_numericSlider__T4ZJC input {
    width: 200px;
  }

.DebugUI_checkbox__PtgZg {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.HeroGradient_heroGradient__OHlVe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.HeroGradient_canvas__1YNtB {
  min-width: 100%;
  height: 80%;
  aspect-ratio: 5 / 4;
}

.QuoteInteractive_quoteInteractive__qCphY {
  display: block;
}

  @media (min-width: 768px) {

  .QuoteInteractive_quoteInteractive__qCphY .QuoteInteractive_quoteInteractiveSm__el2Iv {
      display: none
  }
    }

  .QuoteInteractive_quoteInteractive__qCphY .QuoteInteractive_quoteInteractiveMd__hZwTX {
    display: none;
  }

  @media (min-width: 768px) {

  .QuoteInteractive_quoteInteractive__qCphY .QuoteInteractive_quoteInteractiveMd__hZwTX {
      display: block
  }
    }

  @media (min-width: 1240px) {

  .QuoteInteractive_quoteInteractive__qCphY .QuoteInteractive_quoteInteractiveMd__hZwTX {
      display: none
  }
    }

  .QuoteInteractive_quoteInteractive__qCphY .QuoteInteractive_quoteInteractiveLg__1zhJj {
    display: none;
  }

  @media (min-width: 1240px) {

  .QuoteInteractive_quoteInteractive__qCphY .QuoteInteractive_quoteInteractiveLg__1zhJj {
      display: grid;
      padding-bottom: var(--spacer-84)
  }
    }

.Stats_stats__wBQkA {
  margin-top: var(--spacer-64);
  row-gap: var(--spacer-32);

  --stat-width: 4;
  --label-width: var(--stat-width);
}

  .Stats_stats__wBQkA:first-child {
    margin-top: 0;
  }

  @media (min-width: 768px) {.Stats_stats__wBQkA {
    --stat-width: 2;
    --subgrid-start: col 2;
    --subgrid-width: calc(var(--grid-number-of-columns) - 2);
    margin-top: var(--spacer-64);
    grid-column: var(--subgrid-start) / span var(--subgrid-width);
    row-gap: var(--spacer-24)
}
  }

  @media (min-width: 0px) {

  .Stats_stats__wBQkA.Stats_isAlignCenter__6Olkq.Stats_with3Stats__uoCZi {
      --subgrid-start: col 4
  }
    }

  .Stats_stats__wBQkA.Stats_gridColumnChildIndex0__LE17F {
    margin-top: 0;
  }

  @media (min-width: 768px) {

  .Stats_stats__wBQkA.Stats_gridColumnIndex0__0v0h9,
  .Stats_stats__wBQkA.Stats_gridColumnIndex1__LYd8U {
      --subgrid-start: col 8;
      --subgrid-width: 7
  }
    }

  @media (min-width: 768px) {

  .Stats_stats__wBQkA.Stats_with3Stats__uoCZi {
      --stat-width: 2
  }
    }

  @media (min-width: 768px) {

  .Stats_stats__wBQkA.Stats_with4Stats__4uYDj {
      --stat-width: 2
  }
    }

  .Stats_stats__wBQkA.Stats_isHuge__KK1Ye {
    margin-top: 0;
    row-gap: var(--spacer-48);
  }

  @media (min-width: 768px) {

  .Stats_stats__wBQkA.Stats_isHuge__KK1Ye {
      --stat-width: 5;
      --label-width: 4;
      row-gap: var(--spacer-72)
  }
    }

  @media (min-width: 1240px) {

  .Stats_stats__wBQkA.Stats_isHuge__KK1Ye {
      --label-width: 3
  }
    }

  .Stats_stats__wBQkA.Stats_isNormal__aXPt0 {
    row-gap: var(--spacer-48);
  }

  @media (min-width: 768px) {

  .Stats_stats__wBQkA.Stats_isNormal__aXPt0 {
      display: flex;
      justify-content: center;

      /* custom padding to visually center the stats */
      padding-left: 3.2rem;
      gap: var(--spacer-16);

      --stat-width: 3
  }

      .Stats_stats__wBQkA.Stats_isNormal__aXPt0 .Stats_stat__9b3Ra {
        width: 100%;
        max-width: 17.9rem;
        grid-column: unset;
      }
    }

  @media (min-width: 1240px) {

  .Stats_stats__wBQkA.Stats_isNormal__aXPt0 {
      /* custom padding to visually center the stats */
      padding-left: 5.6rem;
      gap: var(--spacer-72)
  }

      .Stats_stats__wBQkA.Stats_isNormal__aXPt0 .Stats_stat__9b3Ra {
        max-width: 16rem;
      }
    }

.Stats_stat__9b3Ra {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 0;
  grid-column: auto / span var(--stat-width);
}

.Stats_value___hbGs {
  margin-bottom: calc(var(--spacer-16) - 0.28em);
  color: var(--color-grey-100);
}

.Stats_valueHuge__P5lVw {
  padding-right: 0.06em;
  margin-bottom: calc(var(--spacer-16) - 0.28em);
  color: var(--color-grey-100);
}

.Stats_valueAndSuffix__naudv {
  white-space: nowrap;
}

/* Custom values to make sure bottom of value & top of label are aligned */

@media (min-width: 768px) {

.Stats_isHuge__KK1Ye .Stats_valueAndSuffix__naudv {
      margin-bottom: -2.3rem
  }
    }

@media (min-width: 1240px) {

.Stats_isHuge__KK1Ye .Stats_valueAndSuffix__naudv {
      margin-bottom: -3rem
  }
    }

.Stats_valueSuffix__DuxLc {
  position: relative;
  top: 0.08em;
  margin-left: var(--spacer-4);
  color: var(--color-grey-30);
  vertical-align: top;
}

@media (min-width: 768px) {

.Stats_valueSuffix__DuxLc {
    top: 0.2em;
    margin-left: var(--spacer-8)
}
  }

.Stats_valueSuffixHuge__FhD9G {
  position: relative;
  top: 0.08em;
  color: var(--color-grey-30);
  vertical-align: top;
}

@media (min-width: 768px) {

.Stats_valueSuffixHuge__FhD9G {
    top: 0.28em
}
  }

@media (min-width: 1240px) {

.Stats_valueSuffixHuge__FhD9G {
    top: 0.24em
}
  }

.Stats_labelHuge___T3yb,
.Stats_label__e5bmV {
  display: block;
  width: 100%;
  color: var(--color-alpha-light-50);
}

.Stats_label__e5bmV {
}

.Stats_labelHuge___T3yb {
  padding: 0;
}

@media (min-width: 768px) {

.Stats_labelHuge___T3yb {
    width: calc(var(--label-width)/var(--stat-width)*100%);
    padding: 0 var(--spacer-8);
    margin-top: var(--spacer-24)
}
  }

@media (min-width: 1240px) {

.Stats_labelHuge___T3yb {
    margin-top: var(--spacer-12)
}
  }

.Touts_touts__tzgjP {
  grid-column: start / end;
}

.Touts_container__lYFO0 {
  --tout-width: 4;
  --shadow-scale: 0.5;
  --shadow-radius: var(--border-radius-20);
  position: relative;
  row-gap: var(--spacer-32);
}

@media (min-width: 768px) {

.Touts_container__lYFO0 {
    --subgrid-start: start;
    --subgrid-width: var(--grid-number-of-columns)
}
  }

.Touts_container__lYFO0.Touts_toutsOnTiles___kBe9 {
    row-gap: var(--spacer-24);
  }

.Touts_container__lYFO0.Touts_isMobileSlider__ioQe_ {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    grid-column-gap: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

@media (min-width: 768px) {

.Touts_container__lYFO0.Touts_isMobileSlider__ioQe_ {
      display: grid;

      /* put back default gap */
      grid-column-gap: var(--grid-gutter-size, 20px)
  }
    }

.Touts_container__lYFO0.Touts_mobileSmallLayout__z0KoL {
    --tout-width: 3;
  }

.Touts_container__lYFO0.Touts_isColumnLayout__ztHw4 {
    --subgrid-start: var(--column-start);
    --subgrid-width: var(--column-width, var(--tout-width));
  }

.Touts_container__lYFO0.Touts_isColumnLayout__ztHw4 .Touts_tout__lhjIQ {
      position: relative;
    }

@media (min-width: 768px) {

.Touts_container__lYFO0.Touts_with3Touts___h2Qs {
      --tout-width: 4
  }
    }

@media (min-width: 768px) {

.Touts_container__lYFO0.Touts_with4Touts___VPox {
      --tout-width: 6
  }
    }

@media (min-width: 1240px) {

.Touts_container__lYFO0.Touts_with4Touts___VPox {
      --tout-width: 3
  }
    }

.Touts_paginationDots__O1CWY {
  display: block;
  margin-top: var(--spacer-24);
}

@media (min-width: 768px) {

.Touts_paginationDots__O1CWY {
    display: none
}
  }

.Touts_tout__lhjIQ {
  position: relative;
  grid-column: auto / span var(--tout-width);
}

.Touts_toutInnerWrapper__DvOcQ {
  position: relative;
  width: 100%;
  flex-basis: var(--grid-computed-inner-width);
  flex-shrink: 0;
  grid-column: auto / span var(--tout-width);
}

@media (min-width: 768px) {

.Touts_toutInnerWrapper__DvOcQ {
    flex-basis: initial
}
  }

.Touts_iconWrapper__6zJ9y {
  position: relative;
  display: flex;
  width: auto;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-bottom: var(--spacer-8);
}

.Touts_iconWrapper__6zJ9y.Touts_isLogo__M0SBA {
    align-items: center;
    margin-bottom: var(--spacer-16);
  }

.Touts_iconWrapper__6zJ9y:not(.Touts_isLogo__M0SBA) .Touts_icon__JWG1q * {
        fill: var(--color-accent);
      }

.Touts_hidden__qjNbx {
  display: none;
}

.Touts_backgroundGlow__5NnXz {
  position: absolute;
  z-index: var(--z-index-neg);
  inset: 0;
  transform: translate3d(0, 25%, 0);
}

@media (min-width: 768px) {

.Touts_backgroundGlow__5NnXz {
    transform: translate3d(0, 40%, 0)
}
  }

