.debug {
  composes: grid from '~g';
  position: fixed;
  z-index: var(--z-index-neg);
  width: 100vw;
  inset: 0;
  pointer-events: none;
  visibility: hidden;
  @media (--md) {
    width: 100%;
  }
}

.visible {
  z-index: var(--z-index-max);
  visibility: visible;
}

.column {
  background-color: var(--color-cobalt);
  opacity: 0.3;
}

.fpsCounter {
  composes: title4 from '~typo';
  position: fixed;
  z-index: var(--z-index-max);
  top: 10px;
  left: 10px;
  color: red;
  opacity: 0.5;
}
