.heroGradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.canvas {
  min-width: 100%;
  height: 80%;
  aspect-ratio: 5 / 4;
}
