.stats {
  composes: subgrid from '~g';
  margin-top: var(--spacer-64);
  row-gap: var(--spacer-32);

  --stat-width: 4;
  --label-width: var(--stat-width);

  &:first-child {
    margin-top: 0;
  }

  @media (--md) {
    --stat-width: 2;
    --subgrid-start: col 2;
    --subgrid-width: calc(var(--grid-number-of-columns) - 2);
    margin-top: var(--spacer-64);
    grid-column: var(--subgrid-start) / span var(--subgrid-width);
    row-gap: var(--spacer-24);
  }

  &.isAlignCenter.with3Stats {
    @media (--sm) {
      --subgrid-start: col 4;
    }
  }

  &.gridColumnChildIndex0 {
    margin-top: 0;
  }

  &.gridColumnIndex0,
  &.gridColumnIndex1 {
    @media (--md) {
      --subgrid-start: col 8;
      --subgrid-width: 7;
    }
  }

  &.with3Stats {
    @media (--md) {
      --stat-width: 2;
    }
  }

  &.with4Stats {
    @media (--md) {
      --stat-width: 2;
    }
  }

  &.isHuge {
    margin-top: 0;
    row-gap: var(--spacer-48);

    @media (--md) {
      --stat-width: 5;
      --label-width: 4;
      row-gap: var(--spacer-72);
    }

    @media (--lg) {
      --label-width: 3;
    }
  }

  &.isNormal {
    row-gap: var(--spacer-48);

    @media (--md) {
      display: flex;
      justify-content: center;

      /* custom padding to visually center the stats */
      padding-left: 3.2rem;
      gap: var(--spacer-16);

      --stat-width: 3;

      .stat {
        width: 100%;
        max-width: 17.9rem;
        grid-column: unset;
      }
    }

    @media (--lg) {
      /* custom padding to visually center the stats */
      padding-left: 5.6rem;
      gap: var(--spacer-72);

      .stat {
        max-width: 16rem;
      }
    }
  }
}

.stat {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 0;
  grid-column: auto / span var(--stat-width);
}

.value {
  composes: stats from '~typo';
  margin-bottom: calc(var(--spacer-16) - 0.28em);
  color: var(--color-grey-100);
}

.valueHuge {
  composes: hugeStats from '~typo';
  padding-right: 0.06em;
  margin-bottom: calc(var(--spacer-16) - 0.28em);
  color: var(--color-grey-100);
}

.valueAndSuffix {
  white-space: nowrap;

  /* Custom values to make sure bottom of value & top of label are aligned */
  .isHuge & {
    @media (--md) {
      margin-bottom: -2.3rem;
    }

    @media (--lg) {
      margin-bottom: -3rem;
    }
  }
}

.valueSuffix {
  composes: statsSuffix from '~typo';
  composes: statSuffixRegularGradient from '~deco';
  position: relative;
  top: 0.08em;
  margin-left: var(--spacer-4);
  color: var(--color-grey-30);
  vertical-align: top;

  @media (--md) {
    top: 0.2em;
    margin-left: var(--spacer-8);
  }
}

.valueSuffixHuge {
  composes: statSuffixRegularGradient from '~deco';
  composes: hugeStatsSuffix from '~typo';
  position: relative;
  top: 0.08em;
  color: var(--color-grey-30);
  vertical-align: top;

  @media (--md) {
    top: 0.28em;
  }
  @media (--lg) {
    top: 0.24em;
  }
}

.labelHuge,
.label {
  display: block;
  width: 100%;
  color: var(--color-alpha-light-50);
}

.label {
  composes: label from '~typo';
}

.labelHuge {
  composes: hugeStatsLabel from '~typo';
  padding: 0;

  @media (--md) {
    width: calc(var(--label-width) / var(--stat-width) * 100%);
    padding: 0 var(--spacer-8);
    margin-top: var(--spacer-24);
  }

  @media (--lg) {
    margin-top: var(--spacer-12);
  }
}
