.quoteInteractive {
  display: block;

  .quoteInteractiveSm {
    @media (--md) {
      display: none;
    }
  }

  .quoteInteractiveMd {
    display: none;

    @media (--md) {
      display: block;
    }

    @media (--lg) {
      display: none;
    }
  }

  .quoteInteractiveLg {
    display: none;

    @media (--lg) {
      display: grid;
      padding-bottom: var(--spacer-84);
    }
  }
}
